<template>
  <div class="navBox">
    <div class="navLeft" @click="toHome">
      <img src="https://video.wahool.com/b_website/logo.png" alt="" />
    </div>
    <div class="navRight">
      <div class="menuList">
        <div class="menuItem" v-for="(item, index) in menuList" :key="index">
          <template v-if="index !== 2">
            <router-link
              :to="item.path"
              class="hrefs"
              :class="{ menuItemActive: curIndex == index }"
              ><span>{{ item.title }}</span></router-link
            >
          </template>
          <template v-else
            ><span
              :style="{
                color:
                  fullPath == '/partnerPage' || fullPath == '/livestreamPage'
                    ? '#fb5d43'
                    : '',
              }"
              >{{ item.title }}</span
            ></template
          >
          <div class="menuChildList">
            <div class="childList">
              <div
                class="itemChild"
                :class="{ itemChildActive: fullPath == '/partnerPage' }"
                @click="toPath(1)"
              >
                申请成为供货商
              </div>
              <div
                class="itemChild"
                :class="{ itemChildActive: fullPath == '/livestreamPage' }"
                @click="toPath(2)"
              >
                申请直播服务
              </div>
            </div>
          </div>
        </div>
        <div class="loginBtn" @click="logoClick">登录工作台</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from 'vue'
import { useRouter } from 'vue-router'
// import store from '@/store'
import { useStore } from 'vuex'
const props = defineProps({
  curIndex: Number,
})
console.log(props)
const store = useStore()
const router = useRouter()
const menuList = ref([
  { title: '产品分销服务', path: '/' },
  { title: '红人直播服务', path: '/livestream' },
  { title: '商业合作', path: '' },
  { title: '关于我们', path: 'aboutUs' },
])
const fullPath = ref('')
const settingPath = (path) => {
  console.log('监听了路由', path)
  if (path == '/partnerPage' || path == '/livestreamPage') {
    fullPath.value = path
  }
}
const toHome = () => {
  router.push('/')
}
settingPath(router.currentRoute.value.fullPath)
//跳转页面
const toPath = (index) => {
  if (index == 1) {
    router.push({
      path: '/partnerPage',
    })
  } else if (index == 2) {
    router.push({
      path: '/livestreamPage',
    })
  }
}
const logoClick = async () => {
  const token = window.localStorage.getItem('token')
  const userType = window.localStorage.getItem('userType')
  if (userType == 'undefined') {
    await store.commit('removeToken')
    await store.commit('removeUserType')
    router.push({
      path: '/login',
    })
    return
  }
  if (token) {
    console.log('111')
    if (userType && userType == 'Amazon') {
      router.push({
        path: '/live/calendar',
      })
    }
    if (userType && userType == 'Wahool') {
      router.push({
        path: '/supplier-system/goodsManagement',
      })
    }
  } else {
    console.log('222')
    router.push({
      path: '/login',
    })
  }
}
</script>
<style lang="less" scoped>
.hrefs {
  color: #fff;
  padding: 15px 0;
}
.navBox {
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(17, 17, 17, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .navLeft {
    cursor: pointer;
    img {
      display: flex;
      width: 120px;
      height: 18px;
    }
  }
  .navRight {
    display: flex;
    align-items: center;
    .menuList {
      display: flex;
      align-items: center;
      .menuItem {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        margin-right: 40px;
        cursor: pointer;
      }
      .loginBtn {
        background: #fb5d43;
        border-radius: 12px;
        width: 100px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
      .menuItemActive {
        color: #fb5d43;
      }
      .menuChildList {
        display: none;
        position: absolute;
        bottom: -120px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 140px;
        height: 120px;
        background: transparent;
        .childList {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 25px;
          width: 140px;
          padding: 20px 20px;
          box-sizing: border-box;
          height: 106px;
          background: url('https://video.wahool.com/b_website/navShowIcon.png')
            no-repeat;
          background-size: cover;
          .itemChild {
            padding: 10px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #fff;
          }
          .itemChild:first-child {
            border-bottom: 1px solid rgba(39, 23, 23, 0.5);
          }
          .itemChild:hover {
            color: #fb5d43;
          }
        }
      }
      .menuItem .hrefs:hover {
        color: #fb5d43;
      }
      .menuItem:nth-child(3) {
        position: relative;
      }
      .menuItem:nth-child(3):hover .menuChildList {
        display: block;
      }
    }
  }
}
.itemChildActive {
  color: #fb5d43 !important;
}
</style>
