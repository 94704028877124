<template>
  <div class="fonterBox">
    <div class="contentBox">
      <div class="topBox">
        <div class="logoImg">
          <img src="https://video.wahool.com/b_website/logo.png" alt="" />
        </div>
        <div class="cityNameBox">
          <span>北京</span> · <span>广州</span> · <span>纽约</span> ·
          <span>洛杉矶</span>
        </div>
      </div>
      <!-- <div class="centerBox"><span>服务条款</span> · <span>隐私政策</span></div> -->
    </div>
    <div class="botBox">
      <div class="fotLeft">
        <span>Design & Development By wahool . </span>
        <span>京ICP备16001240号-1</span>
      </div>
      <div class="fotRight">Copyright © 2022 wahool ® All rights reserved.</div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
@media screen and(min-width:1200px) and (max-width: 1440px) {
  .contentBox {
    padding-left: 5%;
  }
}
@media screen and(min-width:1440px) {
  .contentBox {
    padding-left: 10%;
  }
}
.fonterBox {
  font-family: 'Lantinghei SC';
  width: 100%;
  background: #111111;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .contentBox {
    // min-width: 1300px;
    width: 100%;
    padding-bottom: 200px;
    .topBox {
      .logoImg {
        img {
          width: 120px;
          height: 18px;
          display: flex;
        }
      }
      .cityNameBox {
        margin-top: 40px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
      }
    }
    .centerBox {
      margin-top: 200px;
      margin-bottom: 100px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
    }
  }
  .botBox {
    width: 100%;
    // height: 80px;
    padding: 0 38px;
    box-sizing: border-box;
    border-top: 4px solid #fb5d43;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 80px;
    color: #ffffff;
  }
}
</style>
