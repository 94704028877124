<template>
  <div class="messageBox">
    <div class="inputBox">
      <div class="tit"></div>
      <div class="inputItem items">
        <div style="width: 38%">
          <a-input
            class="inputs"
            style="width: 100%"
            v-model:value="userName"
            placeholder="姓名"
          />
        </div>
        <div style="width: 10%">
          <a-radio-group v-model:value="gender">
            <a-radio value="GENTLEMAN">先生</a-radio>
            <a-radio value="LADY">女士</a-radio>
          </a-radio-group>
        </div>
        <div style="width: 38%">
          <a-input
            style="width: 100%"
            class="inputs"
            v-model:value="phone"
            placeholder="电话"
          />
        </div>
      </div>
      <div class="inputList items">
        <a-input
          style="width: 100%"
          class="inputs"
          v-model:value="wechatNumber"
          placeholder="微信号"
        />
      </div>
      <div class="inputList items">
        <a-input
          style="width: 100%"
          class="inputs"
          v-model:value="storeLink"
          placeholder="店铺链接"
        />
      </div>
      <div class="inputList items">
        <a-input
          style="width: 100%"
          class="inputs"
          v-model:value="mainProductsMonthSales"
          placeholder="主打品月销"
        />
      </div>
      <div class="inputList">
        <a-input
          style="width: 100%; height: 50px"
          class="inputs"
          v-model:value="additionalInformation"
          placeholder="补充信息"
        />
      </div>
      <div class="btn" @click="submit">提交</div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { cooperationCreate } from '@/api/supplier/goodsManagement';
import { message } from 'ant-design-vue';
const userName = ref(''); //姓名
const gender = ref(''); //性别
const phone = ref(''); //手机号
const wechatNumber = ref(''); //微信号
const storeLink = ref(''); //店铺链接
const mainProductsMonthSales = ref(''); //主打品月销
const additionalInformation = ref(''); //补充信息
const submit = () => {
  let payload = {
    cooperationType: 'BRAND_SELLER',
    userName: userName.value,
    gender: gender.value,
    phone: phone.value,
    wechatNumber: wechatNumber.value,
    storeLink: storeLink.value,
    mainProductsMonthSales: mainProductsMonthSales.value,
    additionalInformation: additionalInformation.value,
  };
  cooperationCreate(payload).then((res) => {
    console.log(res, '获取res');
    if (res.code == 200) {
      message.success('提交成功');
      userName.value = '';
      gender.value = '';
      phone.value = '';
      wechatNumber.value = '';
      storeLink.value = '';
      mainProductsMonthSales.value = '';
      additionalInformation.value = '';
    }
  });
};
</script>
<style lang="less" scoped>
.btn {
  margin: 41px auto 0;
  width: 100px;
  height: 40px;
  background: #fb5d43;
  border-radius: 20px;
  font-family: 'Lantinghei SC';
  font-size: 20px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}
.selectBox {
  padding-left: 40px;
  margin-bottom: 30px;
  .selectList {
    margin-top: 30px;
  }
}
.ant-radio-group {
  display: flex;
}
.inputs {
  border-radius: none !important;
  padding-left: 40px;
  height: 50px;
}
.items {
  margin-bottom: 30px;
  height: 50px;
}
.inputItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.messageBox {
  width: 100%;
  padding: 100px 0 122px 0;
  display: flex;
  justify-content: center;
  .inputBox {
    width: 1200px;
    padding: 0 20px;
  }
}
.inputList {
  height: 50px;
}
</style>
