<template>
  <div class="attractCardBox">
    <div class="attractCard">
      <div class="tit">招商合作</div>
      <div class="conList">
        <div class="conItem">
          <div class="img">
            <img
              src="https://video.wahool.com/b_website/home/attrIcon1.png"
              alt=""
            />
          </div>
          <div class="itemTit">品牌卖家</div>
        </div>
        <div class="conItem">
          <div class="img">
            <img
              src="https://video.wahool.com/b_website/home/attrIcon4.png"
              alt=""
            />
          </div>
          <div class="itemTit">品类头部</div>
        </div>
        <div class="conItem">
          <div class="img">
            <img
              style="width: 102px; height: 120px"
              src="https://video.wahool.com/b_website/home/attrIcon5.png"
              alt=""
            />
          </div>
          <div class="itemTit">国货之光</div>
        </div>
        <!-- <div class="conItem">
          <div class="img">
            <img src="https://video.wahool.com/b_website/home/attrIcon4.png" alt="" />
          </div>
          <div class="itemTit">研发创新</div>
          <div class="descBox">
            <div class="descItem">有自主研发和设计能力</div>
            <div class="descItem">新品>10款/月</div>
          </div>
        </div> -->
      </div>
      <!-- <div class="btns">合作洽谈</div> -->
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
.btns {
  width: 140px;
  height: 40px;
  background: #fb5d43;
  border-radius: 20px;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  margin-top: 60px;
  cursor: pointer;
}
.attractCardBox {
  width: 100%;
  padding: 100px 0;
  background: rgba(237, 237, 237, 0.5);
  display: flex;
  justify-content: center;
  font-family: 'Lantinghei SC';
  .attractCard {
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tit {
      font-family: 'Lantinghei SC';
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #111111;
    }
    .conList {
      margin-top: 80px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .conItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          width: 220px;
          height: 220px;
          background: #ffffff;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: flex;
          }
        }

        .itemTit {
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          text-align: center;
          color: #fb5d43;
          mix-blend-mode: normal;
          margin-top: 20px;
          font-family: 'Lantinghei SC';
        }
        .descBox {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .descItem {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #111111;
          }
        }
      }
      .conItem :nth-child(1) .img img {
        width: 91px;
        height: 120px;
      }
      .conItem :nth-child(2) .img img {
        width: 120px;
        height: 110px;
      }
      .conItem :nth-child(3) .img img {
        width: 120px;
        height: 107px;
      }
      .conItem :nth-child(4) .img img {
        width: 103px;
        height: 120px;
      }
    }
  }
}
</style>
