import request from '@/utils/request';

/**
 * 获取商品分页
 * http://yapi.wahool.com/project/29/interface/api/6313
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getGoodsManagementTableData(data = {}) {
    return request.GET('/supplier/store-goods/page', data);
}

/**
 * 获取商品数量
 * http://yapi.wahool.com/project/29/interface/api/6474
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getGoodsManagementTotalsData(data = {}) {
    return request.GET('/supplier/store-goods/totals', data);
}
/**
 * 商家端官网合作信息提交
 * http://yapi.wahool.com/project/29/interface/api/7407
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function cooperationCreate(data = {}) {
    return request.PUT('/supplier/cooperation/create', data);
}